var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:term:table:search",
                  expression: "'production:term:table:search'",
                },
              ],
              staticStyle: { flex: "1" },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "500px" } },
                [
                  _c(
                    "a-row",
                    { attrs: { type: "flex", gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        [
                          _c("a-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入搜索内容" },
                            on: { pressEnter: _vm.searchQuery },
                            model: {
                              value: _vm.searchText,
                              callback: function ($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "search" },
                              on: { click: _vm.searchQuery },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:term:table:delete",
                      expression: "'production:term:table:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "production:term:table:add",
                  expression: "'production:term:table:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.addTable },
            },
            [_vm._v("添加")]
          ),
          _c("div", { staticStyle: { height: "32px" } }),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "production:termtemplate:page:view",
            expression: "'production:termtemplate:page:view'",
          },
        ],
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow: _vm.clickTermsRow,
          scroll: { x: 1000, y: 500 },
          pagination: _vm.ipagination,
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 60,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "sequenceNo",
            fn: function (text, record) {
              return _c("span", {}, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.dataSource.findIndex((r) => r.id == record.id) + 1
                    ) +
                    "\n    "
                ),
              ])
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return _c("span", {}, [
                record.avatar
                  ? _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "border-radius": "50%",
                      },
                      attrs: { src: record.avatar, alt: "" },
                    })
                  : _vm._e(),
                _c("span", { staticStyle: { "margin-left": "3px" } }, [
                  _vm._v(_vm._s(record.username)),
                ]),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "production:term:table:edit",
                          expression: "'production:term:table:edit'",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.editTable(record)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "production:term:table:delete",
                          expression: "'production:term:table:delete'",
                        },
                      ],
                      staticStyle: { "margin-left": "15px", color: "red" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteTable(record)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]
              )
            },
          },
        ]),
      }),
      _c("terms-table-modal", {
        ref: "termsTableModal",
        attrs: { parent: _vm.parent },
        on: { loadData: _vm.loadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }